import { Container } from "@mui/material"
import { Link } from "gatsby"
import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title> Error 404 - Cassandra Link </title>
      <meta
        name="description"
        content="Oops! Page not found on Cassandra Link - Your Source for Cassandra Resources"
      />
      <meta property="og:title" content="Cassandra Link - Error 404" />
      <meta
        property="og:description"
        content="Oops! Page not found on Cassandra Link - Your Source for Cassandra Resources"
      />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://cassandra.link/404" />
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://cassandra.link/",
          "@type": "WebPage",
          name: "Error - Cassandra Link",
          keywords: "Cassandra Link, Error",
          author: {
            "@type": "Organization",
            name: "Cassandra Link",
          },
        })}
      </script>
      {/* Open Graph */}
      <meta property="og:title" content={"Error Page - Cassandra Link"} />

      {/* Other meta tags you may consider adding */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="robots" content="index, follow" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Cassandra Link - Error 404" />
      <meta
        name="twitter:description"
        content="Oops! Page not found on Cassandra Link - Your Source for Cassandra Resources"
      />
      <meta name="twitter:image" content={"../../images/Logo.svg"} />
    </Helmet>
    <SEO title="404: Not found" />
    <Container maxWidth="lg">
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      <Link to="/">Back to home</Link>
    </Container>
  </Layout>
)

export default NotFoundPage
